<template>
  <div class='content-i ng-scope'>
    <div class="content-box ng-scope">
      <!-- START - modal -->
      <DownloadABAFileModal />
      <!-- <PaymentDetailModal /> -->
      <ViewEmployeeModal />
      <!-- END - modal -->
      <div class='col-sm-12'>
        <div class='element-wrapper'>
          <div class="title">
            <div class="txt-title" style="margin-left: 0px">Process Payroll</div>
            <div class="txt-date">({{ utils.formatDateToString(responseRunPayroll.startPayPeriod) }} -
              {{ utils.formatDateToString(responseRunPayroll.endPayPeriod) }})</div>
          </div>
          <div class='element-content' style="margin-top: 20px;">
            <div class='row'>
              <!-- List Run Pay roll -->
              <div v-show="runPayrollStage == 'Adjust'" class="ly-list-run-payroll">
                <div class='' style="align-items: center;">
                  <div class='table-responsive' style="padding-bottom:0px">
                    <div class="payroll-mobile v-application" style="margin-left: 20px">
                      <div style="display: flex; align-items: center">
                        <v-checkbox value @click="checkAll()" :input-value="numberCheck == this.listRunPayroll.length"
                          :indeterminate="numberCheck > 0 && numberCheck < this.listRunPayroll.length"
                          id="checkAll"></v-checkbox>
                        <span v-show="numberCheck > 0" style="margin-left: 5px">{{ numberCheck }}</span>
                      </div>
                    </div>
                    <table class="table table-padded payroll-web" style="min-width: 645px">
                      <thead class="">
                        <tr>
                          <th class="v-application"
                            style="display: flex; width: 50px; align-items: center; justify-content: flex-end;">
                            <v-checkbox value @click="checkAll()"
                              :input-value="numberCheck == this.listRunPayroll.length"
                              :indeterminate="numberCheck > 0 && numberCheck < this.listRunPayroll.length"
                              id="checkAll"></v-checkbox>
                            <span v-show="numberCheck > 0" style="margin: 38px; position: absolute">{{ numberCheck
                              }}</span>
                          </th>
                          <th>Name</th>
                          <th>Work hours</th>
                          <th>Pay rate</th>
                          <th>Gross Earnings</th>
                          <th>
                            <button class="
                                                            btn btn-sm-2
                                                            text-success
                                                            btn-icon-sm
                                                            box-title
                                                        " :title="!isExpandCollapse ? 'Expand All' : 'Collapse All'"
                              @click="checkExpandCollapse()">
                              <v-icon right>{{ !isExpandCollapse ?
                                'mdi-chevron-double-down' : 'mdi-chevron-double-up' }}</v-icon>
                            </button>
                          </th>

                        </tr>
                      </thead>
                      <tbody>

                        <template v-for="(item, index) in listRunPayroll">
                          <tr class="animatedUp ng-scope" v-bind:key="item.employeeId"
                            :class="{ opened: opened.includes(item.employeeId) }">
                            <td class="v-application"
                              style="padding-top: 0px !important; padding-bottom: 0px !important;">
                              <v-checkbox value v-model="item.isactivePayroll" @change="check(item.isactivePayroll)"
                                :id="'isCheckFindMyAddress' + item.id"></v-checkbox>
                            </td>

                            <td style="width: 25%;">
                              <label class="txt-name os-dropdown-trigger choose-employee" style="width: max-content;"
                                @click="getListEmployee(item.employeeId)"> {{ item.employeeData.firstName + ' ' +
                                  item.employeeData.lastName }}</label>
                            </td>
                            <td style="width: 33%">
                              <div class="input-group">
                                <input v-model.number="item.ordinaryWorkHoursRound"
                                  v-on:change="onChangeWeeklyWorkHours(item, index)" class="form-control edit-work" />
                                <i v-show="item.isChangeWorkHours"
                                  class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer"
                                  @click="resetWorkHours(item, index)" style="left: 10px;"></i>
                                <!-- <span :title="item.ordinaryWorkHours"><i class="icon-feather-eye text-primary fs-14 icon-payroll" style="vertical-align: middle; right: 50px;"></i></span> -->
                                <div class="input-group-append">
                                  <div class="input-group-text">hr</div>
                                </div>
                              </div>
                            </td>
                            <td style="width: 33%">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">$</div>
                                </div>
                                <input v-model.number="item.ordinaryPayRateRound"
                                  v-on:change="onChangePayRatePerHour(item, index)" class="form-control edit-rate" />
                                <i v-show="item.isChangePayRate"
                                  class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer"
                                  @click="resetPayRate(item, index)" style="right: 10px;"></i>
                              </div>
                            </td>
                            <td>
                              <div class="gross" style="width: max-content">
                                <span class="txtDola">
                                  {{utils.formatCurrency(
                                    item.listExtraHours.reduce((sum, extra) => sum + (extra.hour * extra.normalRate), 0)
                                    + (item.ordinaryWorkHours * item.originPayRate)
                                    + item.listAllowance.reduce((sum, allowance) => sum + (allowance.cost *
                                      allowance.unit), 0)
                                  )}}
                                </span>
                                <span class="fs-14 txtSuper" style="vertical-align: middle;">+
                                  <span @click="seeSuperDetail(index)" class="text-primary box-title super-detail"
                                    style="vertical-align: middle;cursor: pointer;">
                                    {{ utils.formatCurrency(item.super) }}
                                    <i class="icon-feather-help-circle text-primary fs-14"></i>
                                  </span>
                                </span>
                              </div>
                            </td>
                            <!-- <td> -->
                            <!-- <button v-on:click="handleViewMore(index)" class="btn btn-add-more"
                                style="width: max-content;">View more<img class="icon-button"
                                  src="/assets/img/icon_next.png" /></button> -->
                            <!-- </td> -->
                            <td><button class="
                                                            btn btn-sm-2
                                                            text-success
                                                            btn-icon-sm
                                                            box-title
                                                        "
                                :title="opened.includes(item.employeeId) && isToogle > 0 ? 'Collapse' : 'Expand'"
                                @click="toggle(item)">
                                <v-icon right>
                                  {{ opened.includes(item.employeeId) && isToogle > 0 ? 'mdi-chevron-up ' :
                                    'mdi-chevron-down' }}
                                </v-icon>
                              </button>
                            </td>
                          </tr>
                          <tr v-bind:key="item.employeeData.firstName" v-if="opened.includes(item.employeeId)">
                            <td colspan="8" class="pl-5 pr-5"
                              :class="opened.includes(item.employeeId) && isToogle > 0 ? 'border' : ''">
                              <div class="col-sm-12">
                                <div class="table_design">
                                  <template>

                                    <table :ref="'title' + index" class="table border">
                                      <thead>
                                        <tr>
                                          <th style="width:40%;text-align: left;">Earnings</th>
                                          <th style="width:20% ;text-align: right;">Hrs</th>
                                          <th style="width:20%;text-align: right;">Rate $</th>
                                          <th style="width:20%';text-align: right;">Total $</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{{ 'Ordinary Work Hours' }}</td>
                                          <td class="text-right">{{ (parseFloat(item.ordinaryWorkHours)).toFixed(2) }}
                                          </td>
                                          <td class="text-right">{{ (parseFloat(item.originPayRate)).toFixed(2) }}</td>
                                          <td class="text-right">{{
                                            utils.formatCurrency(item.ordinaryWorkHours * item.originPayRate) }}</td>
                                        </tr>
                                        <tr v-for="(extraHours) in item.listExtraHours"
                                          v-bind:key="extraHours.payslipName">
                                          <td class="text-left">{{ extraHours.payslipName }}</td>
                                          <td class="text-right"><input v-model.number="extraHours.hour"
                                              class="form-control edit-rate" /></td>
                                          <td class="text-right"><input v-model.number="extraHours.normalRate"
                                              class="form-control edit-rate" /></td>
                                          <td class="text-right">{{ utils.formatCurrency(extraHours.hour *
                                            extraHours.normalRate) }}</td>
                                        </tr>

                                        <tr>
                                          <td class="text-left font-weight-bold">Total</td>
                                          <td class="text-right font-weight-bold">
                                            {{ (item.hoursTotal + item.ordinaryWorkHours).toFixed(2) }}</td>
                                          <td class="text-right font-weight-bold">
                                            {{ (item.rateTotal + item.originPayRate).toFixed(2) }}</td>
                                          <td class="text-right font-weight-bold">
                                            {{utils.formatCurrency(
                                              item.listExtraHours.reduce((sum, extra) => sum + (extra.hour *
                                                extra.normalRate), 0)
                                              + (item.ordinaryWorkHours * item.originPayRate)
                                            )}}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table  border">
                                      <thead>
                                        <tr>
                                          <th style="width:40%">Allowance </th>
                                          <th style="width:20%;text-align: right;">Unit</th>
                                          <th style="width:20%;text-align: right;">Cost $</th>
                                          <th style="width:20%;text-align: right;">Total $</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(allowance) in item.listAllowance" v-bind:key="allowance.employeeId">
                                          <td>{{ allowance.type }}</td>
                                          <td class="text-right"><input v-model.number="allowance.unit"
                                              class="form-control edit-rate" /></td>
                                          <td class="text-right"><input v-model.number="allowance.cost"
                                              class="form-control edit-rate" /></td>
                                          <td class="text-right">{{ (allowance.cost * allowance.unit).toFixed(2) }}</td>
                                        </tr>
                                        <tr>
                                          <td class="text-left font-weight-bold">Total</td>
                                          <!-- <td class="text-right font-weight-bold">{{item.unitTotal.toFixed(2)}}</td> -->
                                          <td class="text-right font-weight-bold">
                                            {{item.listAllowance.length > 0 ? item.listAllowance.reduce((sum,
                                              allowance) => sum + allowance.unit, 0).toFixed(2) : '0.00'}}
                                          </td>
                                          <td class="text-right font-weight-bold">
                                            {{item.listAllowance.length > 0 ? item.listAllowance.reduce((sum,
                                              allowance) => sum + allowance.cost, 0).toFixed(2) : '0.00'}}
                                          </td>
                                          <!-- <td class="text-right font-weight-bold">{{ allowance.cost.toFixed(2)}}</td> -->
                                          <!-- <td class="text-right font-weight-bold">{{ utils.formatCurrency(item.totalAllowance) }}</td> -->
                                          <td class="text-right font-weight-bold">{{utils.formatCurrency(
                                            item.listAllowance.reduce((sum, allowance) => sum + (allowance.cost *
                                              allowance.unit), 0)
                                          )}}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table  border"
                                      v-if="item.listTermination.length > 0">
                                      <thead>
                                        <tr>
                                          <th style="width:40%">Termination Payment
                                            ({{ formatDate(item.listTermination[0].terminationDate) }})</th>
                                          <th style="width:20%;text-align: right;">Hrs</th>
                                          <th style="width:20%;text-align: right;">Cost $</th>
                                          <th style="width:20%;text-align: right;">Total $</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(termination) in item.listTermination" v-bind:key="termination.id">
                                          <td>{{ termination.type }}</td>
                                          <td class="text-right">{{ (parseFloat(termination.unusedLeave)).toFixed(2) }}
                                          </td>
                                          <td class="text-right">
                                            {{ (parseFloat(termination.amountTermination)).toFixed(2) }}</td>
                                          <td class="text-right">
                                            {{ (parseFloat(termination.unusedLeave *
                                              termination.amountTermination)).toFixed(2) }}
                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table ">
                                      <thead>
                                        <tr>
                                          <td style="width:40%"></td>
                                          <td style="width:20%;text-align: right;"></td>
                                          <td class="font-weight-bold" style="width:20%;text-align: right; color:#000">
                                            Gross Wages</td>
                                          <!-- <td class="font-weight-bold" style="width:20%;text-align: right; color:#000">{{ utils.formatCurrency(item.earnings) }}</td> -->
                                          <td class="font-weight-bold" style="width:20%;text-align: right; color:#000">
                                            {{utils.formatCurrency(
                                              item.listExtraHours.reduce((sum, extra) => sum + (extra.hour *
                                                extra.normalRate), 0)
                                              + (item.ordinaryWorkHours * item.originPayRate)
                                              + item.listAllowance.reduce((sum, allowance) => sum + (allowance.cost *
                                                allowance.unit), 0))}}
                                          </td>
                                        </tr>
                                      </thead>

                                    </table>
                                    <table :ref="'title' + index" class="table  border">
                                      <thead>
                                        <tr>
                                          <th style="width:40%">Deduction</th>
                                          <th style="width:20%;text-align: right;"></th>
                                          <th style="width:20%;text-align: right;"></th>
                                          <th style="width:20%;text-align: right;">Total $</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(deductions) in item.listDeduction"
                                          v-bind:key="deductions.employeeId">

                                          <td class="text-left">{{ deductions.otherName !== null ? deductions.type + '-'
                                            +
                                            deductions.otherName : deductions.type }}</td>
                                          <td class="text-right"></td>
                                          <td class="text-right"></td>
                                          <td class="text-right">{{ deductions.amountDeduction.toFixed(2) }}</td>
                                        </tr>

                                        <tr>

                                          <td class="text-left font-weight-bold">Total</td>
                                          <td class="text-right"></td>
                                          <td class="text-right"></td>
                                          <td class="text-right font-weight-bold">{{
                                            utils.formatCurrency(item.totalDeduction) }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table  border">
                                      <thead>
                                        <tr>
                                          <th style="width:40%;">Taxes</th>
                                          <th style="width:20%;text-align: right;"></th>
                                          <th style="width:20%;text-align: right;"></th>
                                          <th style="width:20%;text-align: right;"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="text-left">PAYG</td>
                                          <td class="text-right font-weight-bold"></td>
                                          <td class="text-right"></td>
                                          <td class="text-right font-weight-bold">{{ utils.formatCurrency(item.tax) }}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="text-left font-weight-bold">NET</td>
                                          <td class="text-right"></td>
                                          <td class="text-right"></td>
                                          <td class="text-right font-weight-bold">{{ utils.formatCurrency(item.netPay)
                                            }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table  border">
                                      <thead>
                                        <tr>
                                          <th style="width:40% ;text-align: left;">Super Contributions</th>
                                          <th style="width:20%;text-align: right;"></th>
                                          <th style="width:20%;text-align: right;"></th>
                                          <th style="width:20%;text-align: right;"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="text-left">Super guarantee</td>
                                          <td class="text-right"></td>
                                          <td class="text-right"></td>
                                          <td class="text-right">{{ utils.formatCurrency(item.super) }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table  border">
                                      <thead>
                                        <tr>
                                          <th style="width:40%; text-align: left;">Accrued Entitlements</th>
                                          <th style="width:20%;text-align: right;">Accrued</th>
                                          <th style="width:20%;text-align: right;">Taken</th>
                                          <th style="width:20%;text-align: right;">Balance</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="text-left">Annual Leave - Hrs</td>
                                          <td class="text-right">{{ item.annualLeaveAccrued.toFixed(2) }}</td>
                                          <td class="text-right">{{ (item.annualLeaveTaken || 0).toFixed(2) }}</td>
                                          <td class="text-right">{{ item.totalAnnualLeaveUsed > 0 ?
                                            (item.annualLeaveAccrued
                                              - item.totalAnnualLeaveUsed).toFixed(2) : item.annualLeaveAccrued.toFixed(2)
                                          }}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="text-left">Sick Leave - Hrs</td>
                                          <td class="text-right">{{ item.sickLeaveAccrued.toFixed(2) }}</td>
                                          <td class="text-right">{{ (item.sickLeaveTaken || 0).toFixed(2) }}</td>
                                          <td class="text-right">{{ item.totalSickLeaveUsed > 0 ? (item.sickLeaveAccrued
                                            - item.totalSickLeaveUsed).toFixed(2) : item.sickLeaveAccrued.toFixed(2) }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table :ref="'title' + index" class="table border" v-if="item.note">
                                      <thead>
                                        <tr>
                                          <th style="text-align: left;">Notes</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="text-left" style="text-align: left;">{{ item.note }}</td>
                                        </tr>
                                      </tbody>

                                    </table>
                                  </template>
                                </div>

                              </div>
                              <payment-detail :key="item.employeeId" :currentIndexComponent="index"
                                :currentEmployees="item" :isSickLeaveUsed="isSickLeaveUsed"
                                :hasSuperSalary="hasSuperSalary" :isVisibeChangeData="isVisibeChangeData" />
                              <!-- :listDropdown="listDropdown" -->
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <table class="table table-padded payroll-mobile">
                      <tbody>
                        <tr v-for="(item, index) in listRunPayroll" :key="item.id" class="animatedUp ng-scope">
                          <div class="row item">
                            <div class="col-auto v-application" style="width: 50px">
                              <v-checkbox value v-model="item.isactivePayroll" @change="check(item.isactivePayroll)"
                                :id="'isCheckFindMyAddress' + item.id"></v-checkbox>
                            </div>
                            <div class="col-sm-3">
                              <label class="txt-name os-dropdown-trigger choose-employee"
                                @click="getListEmployee(item.employeeId)">{{
                                  item.employeeData.firstName + ' ' +
                                  item.employeeData.lastName
                                }}</label>
                            </div>
                            <div class="col-sm-4">
                              <div class="row">
                                <div class="col-sm-6" style="padding-left: 0px;">
                                  <div class="input-group" style="margin: 3px 0px 3px 0px;">
                                    <input v-model.number="item.ordinaryWorkHoursRound"
                                      v-on:change="onChangeWeeklyWorkHours(item, index)"
                                      class="form-control edit-work" />
                                    <i v-show="item.isChangeWorkHours"
                                      class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer"
                                      @click="resetWorkHours(item, index)" style="left: 10px;"></i>
                                    <div class="input-group-append">
                                      <div class="input-group-text">hr</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6" style="padding-left: 0px;">
                                  <div class="input-group" style="margin: 3px 0px 3px 0px;">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">$</div>
                                    </div>
                                    <input v-model.number="item.ordinaryPayRateRound"
                                      v-on:change="onChangePayRatePerHour(item, index)"
                                      class="form-control edit-rate" />
                                    <i v-show="item.isChangePayRate"
                                      class="icon-feather-rotate-ccw text-primary ng-scope icon-payroll pointer"
                                      @click="resetPayRate(item, index)" style="right: 10px;"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-3">
                              <b style="color: rgb(124, 124, 124); font-size: 11px;">Gross Earnings</b>
                              <div class="gross">
                                <span class="txtDola">{{ utils.formatCurrency(item.earnings) }}</span>
                                <span class="fs-14 txtSuper" style="vertical-align: middle;">+
                                  <span @click="seeSuperDetail(index)" class="text-primary"
                                    style="vertical-align: middle;">
                                    {{ utils.formatCurrency(item.super) }}
                                    <i class="icon-feather-help-circle text-primary fs-14"></i>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-1">
                              <button class="btn btn-sm-2 text-success btn-icon-sm box-title"
                                :title="opened.includes(item.employeeId) && isToogle > 0 ? 'Collapse' : 'Expand'"
                                @click="toggle(item)">
                                <v-icon right>
                                  {{ opened.includes(item.employeeId) && isToogle > 0 ? 'mdi-chevron-up' :
                                    'mdi-chevron-down' }}
                                </v-icon>
                              </button>
                            </div>
                          </div>
                          <div v-bind:key="item.employeeData.firstName" v-if="opened.includes(item.employeeId)">
                            <div class="pl-5 pr-5"
                              :class="opened.includes(item.employeeId) && isToogle > 0 ? 'border' : ''">
                              <payment-detail :key="item.employeeId" :currentIndexComponent="index"
                                :currentEmployees="item" :isSickLeaveUsed="isSickLeaveUsed"
                                :hasSuperSalary="hasSuperSalary" :isVisibeChangeData=true />
                            </div>
                          </div>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- Review Run Pay roll -->
              <div v-show="runPayrollStage == 'Review'" class="ng-scope" style="margin-top: 20px; width: 100%;">
                <div class="element-box-tp">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Employees</th>
                        <th class="text-right">Earnings</th>
                        <th class="text-right" style="padding-right:26px">Tax</th>
                        <th class="text-right">Deduction</th>
                        <th class="text-right">Net pay</th>
                        <th class="text-right">Amount paid</th>
                        <th class="text-right">Super</th>
                        <!-- <th class="text-center text-primary pointer" ng-click="finalInfo()">EOFY Final</th> -->
                      </tr>
                    </thead>
                    <tbody v-for="(item) in listReviewPayroll" :key="item.id" v-show="item.activePayroll == 'ACTIVE'"
                      v-bind:class="(item.isChangeInput) ? 'has-Tax-Template' : ''" class="ng-scope tr-group">
                      <tr v-bind:class="(item.isChangeInput) ? 'has-Tax-Template' : 'transparent'"
                        style="background-color: #fff !important">
                        <td v-show="item.isChangeInput" style="width: 10px;text-align: center;">
                          <i class="icon-feather-rotate-ccw text-primary ng-scope"
                            @click="resetData(item.employeeData.id)" style=""></i>
                        </td>
                        <td v-show="!item.isChangeInput" style="width: 10px;text-align: center;">

                        </td>
                        <td class="ng-binding" style="display: grid">
                          <span>
                            {{ item.employeeData.firstName + ' ' + item.employeeData.lastName }}
                          </span>
                          <span v-show="item.isTermination" style="font-size: 12px; color: red">
                            (Termination)
                          </span>
                        </td>
                        <td class="text-right ng-binding">
                          <span v-show="item.isTermination" v-on:click="seeETPEarning(item)"><i
                              class="icon-feather-help-circle text-primary fs-14 pointer box-title etp-earning"></i></span>
                          {{ utils.formatCurrency(item.earnings + item.terminationPay + item.lumSumD + item.etpCodeR +
                            item.etpTypeO)
                          }}
                        </td>
                        <td class="text-right">
                          <span v-show="item.isTermination" v-on:click="seeETPTax(item)"><i
                              class="icon-feather-help-circle text-primary fs-14 pointer box-title etp-tax"></i></span>
                          <div class="input-no-line">
                            <currency-input v-model="item.temporaryTax" v-on:change="onChangeTax(item)" locale='en'
                              currency='USD'
                              class="text-right ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-step"
                              style="max-width: 80px;" />
                          </div>
                        </td>
                        <td class="text-right ng-binding">{{ utils.formatCurrency(runPayRoll.getDeduction(item)) }}</td>
                        <td class="text-right ng-binding">{{ utils.formatCurrency(runPayRoll.getNetPay(item)) }}</td>
                        <td class="text-right ng-binding">{{ utils.formatCurrency(runPayRoll.getamountPaid(item)) }}
                        </td>
                        <td class="text-right ng-binding">{{ utils.formatCurrency(item.super) }}</td>
                        <td class="text-center">
                          <!-- <input type="checkbox" disabled :checked="item.eofyFinal" class="ng-pristine ng-untouched ng-valid ng-empty"> -->
                        </td>
                      </tr>
                      <!-- <tr v-if="item.listTermination.length > 0" class="adjacent">
                                            <td></td>
                                            <td class="bold text-left">
                                                Termination payment
                                            </td>
                                            <td class="text-right ng-binding">
                                                <span title="ETP Earning" v-on:click="seeETPEarning(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer"></i></span>
                                                ${{roundNumber(item.terminationPay + item.lumSumD + item.etpCodeR + item.etpTypeO)}}
                                            </td>
                                            <td class="text-right ng-binding">
                                                <span title="ETP Withholding Tax" v-on:click="seeETPTax(item)"><i class="icon-feather-help-circle text-primary fs-14 pointer"></i></span>
                                                ${{roundNumber(item.taxTerminationPay + item.taxETPTypeO + item.taxETPTypeR)}}
                                            </td>
                                            <td colspan="5"></td>
                                        </tr> -->
                    </tbody>
                    <tbody>
                      <tr class="tr-total">
                        <td class="bold">Total</td>
                        <td></td>
                        <td class="text-right ng-binding bold">{{ utils.formatCurrency(responseReview.totalEarnings) }}
                        </td>
                        <td class="text-right ng-binding bold">{{
                          utils.formatCurrency(runPayRoll.getTotalTax(listReviewPayroll)) }}
                        </td>
                        <td class="text-right ng-binding bold">{{
                          utils.formatCurrency(runPayRoll.getTotalDeduction(listReviewPayroll)) }}</td>
                        <td class="text-right ng-binding bold">{{
                          utils.formatCurrency(runPayRoll.getTotalNetPay(listReviewPayroll))
                          }}</td>
                        <td class="text-right ng-binding bold">{{
                          utils.formatCurrency(runPayRoll.getTotalAmountPaid(listReviewPayroll)) }}</td>
                        <td class="text-right ng-binding bold">{{ utils.formatCurrency(responseReview.totalSuper) }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row fade">
                  <div class="col-12 text-panel-show-right">
                    <a class="btn btn-light btn-medium " style="background: white"
                      v-on:click="nextTabsAdjust()">PREVIOUS</a>
                    <button class="btn btn-medium ng-binding btn-dark" v-on:click="saveDraft()">SAVE DRAFT</button>
                    <button class="btn btn-warning btn-medium mr-lg-3 mr-xl-0 ng-binding"
                      v-on:click="submitRunPayroll()">DONE</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Button Next And Cancel -->
            <div class='row' v-show="runPayrollStage == 'Adjust'">
              <div class="ly-button">
                <button v-on:click="goToRunOverview()" class="btn btn-dark">Back <img class="icon-button"
                    src="/assets/img/icon_cancel.png" /></button>
                <button v-bind:disabled="this.numberCheck > 0 ? false : true" v-on:click="reviewRunPayroll()"
                  class="btn btn-primary">Next <img class="icon-button" src="/assets/img/icon_next.png" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  REVIEW_RUNPAYROLL,
  DRAFT_RUNPAYROLL,
  SUBMIT_RUNPAYROLL,
  GET_EMPLOYEE
} from "@/store/actionsType";

import DownloadABAFileModal from './modal/DownloadABAFileModal'
// import PaymentDetailModal from './modal/paymentDetailModal'
import PaymentDetail from '@/components/PaymentDetail.vue'
import ViewEmployeeModal from './modal/ViewEmployeeModal'

import * as Utils from "@/utils/Utils.js";
import * as Session from "@/utils/SessionStore"
import * as Encode from "@/utils/Encodepassword";
// import ApiManager from '@/api/ApiManager.js';
// import moment from "moment";

import * as RunPayRoll from './RunPayRoll'
import log4javascript from 'log4javascript';

export default {
  name: "RunPayroll",
  props: [],
  components: {
    DownloadABAFileModal,
    // PaymentDetailModal,
    ViewEmployeeModal,
    PaymentDetail
  },

  data() {
    return {
      isExpandCollapse: false,
      isVisibeChangeData: false,
      isToogle: false, //for expand prepend
      opened: [],//for expand prepend
      selectedEmpoyeeToogle: "", //for expand prepend
      styleObject: {
        border: '1px solid #000',
        'border-top': '0'
      },
      selectedEmployee: [],
      selectedIndex: 0,
      utils: Utils,
      runPayRoll: RunPayRoll,
      runPayrollStage: "Adjust",
      currentEmployees: "",
      currentIndex: 0,
      numberCheck: 0,
      // run Payroll
      responseRunPayroll: "",
      listRunPayroll: [],
      // Review run payroll
      responseReview: "",
      listReviewPayroll: [],
      annualLeaveAccrued: 0,
      sickLeaveAccrued: 0,
      tax: 0,
      listDataBackUp: [],

      // Payment
      listBonous: [],
      listExtraHours: [],
      listAllowance: [],
      listDeduction: [],
      listTermination: [],
      //Leave
      isAnnualLeaveUsed: false,
      isSickLeaveUsed: false,
      isCheckValidate: true,
      annualLeaveLoading: 0,
      isDisableLeaveUsed: false,

      //Annual Leave
      dateAnnualLeave: "",
      calculateDateAnnual: 2,
      startDateAnnualLeave: "",
      endDateAnnualLeave: "",
      annualLeaveUsed: 0,
      isDisableSickUsed: false,

      //Sick Leave
      dateSickLeave: "",
      calculateDateSick: 2,
      startDateSickLeave: "",
      endDateSickLeave: "",
      sickLeaveUsed: 0,
      unusedLeave: 0,
    };
  },

  created: function () {

    this.$root.$refs.RunPayroll = this;
    this.$logger = log4javascript.getLogger("CreateRunPayRoll");
    if (Session.has("runpayroll")) {
      this.responseRunPayroll = Session.get("runpayroll")
      if (this.responseRunPayroll && this.responseRunPayroll.adjustReview) {
        this.listRunPayroll = this.responseRunPayroll.adjustReview

        this.numberCheck = this.listRunPayroll.length

        for (var i = 0; i < this.listRunPayroll.length; i++) {
          this.listRunPayroll[i].hoursTotal = 0;
          this.listRunPayroll[i].rateTotal = 0;
          this.listRunPayroll[i].unitTotal = 0;
          this.listRunPayroll[i].costTotal = 0
          for (var j = 0; j < this.listRunPayroll[i].listExtraHours.length; j++) {
            this.listRunPayroll[i].hoursTotal = parseFloat(this.listRunPayroll[i].hoursTotal) + (this.listRunPayroll[i].listExtraHours[j].hour);
            this.listRunPayroll[i].rateTotal = parseFloat(this.listRunPayroll[i].rateTotal) + (this.listRunPayroll[i].listExtraHours[j].normalRate);


          }
          for (var k = 0; k < this.listRunPayroll[i].listAllowance.length; k++) {
            this.listRunPayroll[i].unitTotal = parseFloat(this.listRunPayroll[i].unitTotal) + parseFloat(this.listRunPayroll[i].listAllowance[k].unit);
            this.listRunPayroll[i].costTotal = parseFloat(this.listRunPayroll[i].costTotal) + parseFloat(this.listRunPayroll[i].listAllowance[k].cost);

          }

          if (this.listRunPayroll[i].activePayroll == 'ACTIVE') {
            this.listRunPayroll[i].isactivePayroll = true
            this.listRunPayroll[i].ordinaryWorkHoursRound = this.roundNumber(this.listRunPayroll[i].ordinaryWorkHours, "RQ")
            this.listRunPayroll[i].isChangeWorkHours = false;
            this.listRunPayroll[i].originWorkHours = this.listRunPayroll[i].ordinaryWorkHours
            this.listRunPayroll[i].ordinaryPayRateRound = this.roundNumber(this.listRunPayroll[i].ordinaryPayRate, "RQ")
            this.listRunPayroll[i].isChangePayRate = false;
            this.listRunPayroll[i].originPayRate = this.listRunPayroll[i].ordinaryPayRate
          } else {
            this.listRunPayroll[i].isactivePayroll = false
            this.listRunPayroll[i].ordinaryWorkHoursRound = this.roundNumber(this.listRunPayroll[i].ordinaryWorkHours, "RQ")
            this.listRunPayroll[i].isChangeWorkHours = false;
            this.listRunPayroll[i].originWorkHours = this.listRunPayroll[i].ordinaryWorkHours
            this.listRunPayroll[i].ordinaryPayRateRound = this.roundNumber(this.listRunPayroll[i].ordinaryPayRate, "RQ")
            this.listRunPayroll[i].isChangePayRate = false;
            this.listRunPayroll[i].originPayRate = this.listRunPayroll[i].ordinaryPayRate
          }
        }
      }
    } else {
      this.goToRunOverview()
    }
  },
  methods: {
    formatDate(inputDate) {
      var m_names = new Array("Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
      var date = new Date(inputDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var dateShow = day + "-" + m_names[month] + "-" + year;
      return dateShow
    },
    // openModalViewMore(index) {
    //   this.$modal.show('modal-paymentdetail', {
    //     currentIndex: index,
    //     currentEmployees: this.listRunPayroll[index],
    //     startPayPeriod: this.responseRunPayroll.startPayPeriod,
    //     endPayPeriod: this.responseRunPayroll.endPayPeriod,
    //   });
    // },
    checkDataPayRoll() {
      var codeCurrentEmployees = Encode.encrypt(this.currentEmployees);
      var codeOldData = Encode.encrypt(this.oldData);
      if (codeOldData != codeCurrentEmployees) {
        this.changeData = true;
      } else {
        this.changeData = true;
      }
    },

    toggle(item) {
      const index = this.opened.indexOf(item.employeeId);
      if (index > -1) {
        this.isToogle = false;
        this.opened.splice(index, 1);
      } else {
        this.isToogle = true;
        this.selectedEmpoyeeToogle = item;
        this.opened.push(item.employeeId);
        if (this.opened.length === this.listRunPayroll.length) {
          this.isExpandCollapse = true;
        }
      }


    },
    checkExpandCollapse() {
      this.isExpandCollapse = !this.isExpandCollapse
      if (this.isExpandCollapse === true) {
        this.listRunPayroll.map((payRollData) => {
          this.isToogle = true;
          this.selectedEmpoyeeToogle = payRollData;
          this.opened.push(payRollData.employeeId);
        });
      } else {
        this.listRunPayroll.map((payRollData) => {
          const index = this.opened.indexOf(payRollData.employeeId);
          this.opened.splice(index, this.opened.length);
          this.isToogle = false;
        })
      }
    },
    round(num) {
      // num = 1.499;
      var floor = Math.round(num * 100) / 100;
      if (num >= (floor + 0.005)) {
        floor = Math.round((floor + 0.01) * 100) / 100;
      }
      if (floor == Math.floor(floor)) {
        floor += ".00";
      } else if (floor * 10 == Math.floor(floor * 10)) {
        floor += "0";
      }

      return floor;
    },
    // CALL API
    getListEmployee(id) {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start()
        this.$store.dispatch(GET_EMPLOYEE, Session.get("currenCompanyId"))
          .then((response) => {
            this.$root.$refs.AppCyrus.finish()
            var listEmployees = response
            for (var i = 0; i < listEmployees.length; i++) {
              if (id == listEmployees[i].id) {
                this.$modal.show('modal-viewEmployee', {
                  employee: listEmployees[i]
                });
                break
              }
            }
          }).catch(() => {
            this.$root.$refs.AppCyrus.fail()
          });
      } else {
        if (this.$root.$refs.BaseLayout != undefined) {
          this.$root.$refs.BaseLayout.logout()
        }
      }
    },

    reviewRunPayroll() {
      this.$logger.debug("reviewRunPayroll");
      for (var j = 0; j < this.listRunPayroll.length; j++) {
        if (this.listRunPayroll[j].isactivePayroll) {
          this.listRunPayroll[j].activePayroll = 'ACTIVE'
        } else {
          this.listRunPayroll[j].activePayroll = 'INACTIVE'
        }
        this.listRunPayroll[j].employeeData.superGuarantee = 0
      }
      this.responseRunPayroll.adjustReview = this.listRunPayroll
      this.$root.$refs.AppCyrus.start()
      this.$store.dispatch(REVIEW_RUNPAYROLL, this.responseRunPayroll)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish()
          this.listDataBackUp = []
          this.runPayrollStage = "Review"
          this.responseReview = response
          this.listReviewPayroll = response.adjustReview
          if (this.listReviewPayroll.length > 0) {
            for (var i = 0; i < this.listReviewPayroll.length; i++) {
              this.listReviewPayroll[i].annualLeaveAccrued = this.roundNumber(this.listReviewPayroll[i].annualLeaveAccrued, "RQ")
              this.listReviewPayroll[i].sickLeaveAccrued = this.roundNumber(this.listReviewPayroll[i].sickLeaveAccrued, "RQ")
              this.listReviewPayroll[i].isChangeInput = false
              if (this.listReviewPayroll[i].overiteTax != 0 && this.listReviewPayroll[i].overiteTax != undefined) {
                this.listReviewPayroll[i].temporaryTax = this.roundNumber(this.listReviewPayroll[i].overiteTax, "RQ")
              } else {
                this.listReviewPayroll[i].temporaryTax = this.roundNumber(this.listReviewPayroll[i].tax, "RQ")
              }
              this.listReviewPayroll[i].oldTax = this.listReviewPayroll[i].temporaryTax
              if (this.listReviewPayroll[i].listTermination.length > 0) {
                this.listReviewPayroll[i].isTermination = true
              } else {
                this.listReviewPayroll[i].isTermination = false
              }
              if (this.listReviewPayroll[i].activePayroll == 'ACTIVE') {
                this.listDataBackUp.push({
                  id: this.listReviewPayroll[i].employeeData.id,
                  tax: this.listReviewPayroll[i].temporaryTax,
                  overiteTax: this.listReviewPayroll[i].overiteTax,
                  annualLeaveAccrued: this.listReviewPayroll[i].annualLeaveAccrued,
                  sickLeaveAccrued: this.listReviewPayroll[i].sickLeaveAccrued,
                })
              }
            }
            const employeeId = this.listRunPayroll[this.currentIndex].employeeId;
            if (this.opened.includes(employeeId)) {
              this.opened = this.opened.filter(id => id !== employeeId);
            }
          }
        }).catch(() => {
          this.$root.$refs.AppCyrus.fail()
        });
    },

    saveDraft() {
      this.$root.$refs.AppCyrus.start()
      this.responseReview.tax = this.runPayRoll.getTotalTax(this.listReviewPayroll)
      this.responseReview.netPay = this.runPayRoll.getTotalNetPay(this.listReviewPayroll)
      this.responseReview.amountPaid = this.runPayRoll.getTotalAmountPaid(this.listReviewPayroll)
      this.responseReview.statusPayroll = 'Draft'
      this.$store.dispatch(DRAFT_RUNPAYROLL, this.responseReview)
        .then(() => {
          this.$root.$refs.AppCyrus.finish()
          this.$router.push({
            path: "/payrolls",
          });
        }).catch(() => {
          this.$root.$refs.AppCyrus.fail()
        });
    },

    submitRunPayroll() {
      this.$root.$refs.AppCyrus.start()
      this.responseReview.tax = this.runPayRoll.getTotalTax(this.listReviewPayroll)
      this.responseReview.netPay = this.runPayRoll.getTotalNetPay(this.listReviewPayroll)
      this.responseReview.amountPaid = this.runPayRoll.getTotalAmountPaid(this.listReviewPayroll)
      this.responseReview.statusPayroll = 'Done'
      this.$store.dispatch(SUBMIT_RUNPAYROLL, this.responseReview)
        .then(() => {
          this.$root.$refs.AppCyrus.finish()
          this.$router.push({
            path: "/payrolls",
          });
        }).catch(() => {
          this.$root.$refs.AppCyrus.fail()
        });
    },
    // END CALL API

    //Calculate Earnings and Super for Payroller
    getEarnings() {
      return RunPayRoll.getEarnings(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance, this.listTermination)
    },

    getEarningsTax() {
      return RunPayRoll.getEarningsTax(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance)
    },

    getEarningsSuper() {
      return RunPayRoll.getEarningsSuper(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance)
    },

    getSuper() {
      return RunPayRoll.getSuper(this.currentEmployees, this.listExtraHours, this.listBonous, this.listAllowance)
    },
    //End Calculate Earnings and Super for Payroller

    seeSuperDetail(index) {
      var payRollItem = this.listRunPayroll[index];
      var listExtraHours = payRollItem.listExtraHours
      var listBonous = payRollItem.listBonous
      var listAllowance = payRollItem.listAllowance
      var earnings = payRollItem.ordinaryWorkHours * payRollItem.ordinaryPayRate

      if (listExtraHours.length > 0) {
        for (var i = 0; i < listExtraHours.length; i++) {
          if (listExtraHours[i].option == 'ORDINARY_HOURS') {
            earnings = earnings + listExtraHours[i].hour * listExtraHours[i].rate
          }
        }
      }

      if (listBonous.length > 0) {
        for (var j = 0; j < listBonous.length; j++) {
          if (listBonous[j].type == 'SUBJECT_TO_SUPER') {
            earnings = earnings + listBonous[j].amountBonus
          }
        }
      }

      if (listAllowance.length > 0) {
        for (var x = 0; x < listAllowance.length; x++) {
          if (listAllowance[x].option == 'SUBJECT_TO_TAX_AND_SUPER') {
            earnings = earnings + listAllowance[x].unit * listAllowance[x].cost
          }
        }
      }
      earnings = (payRollItem.contributionRate * earnings) / 100
      this.$modal.show('modal-super-detail', {
        superOgirin: earnings,
        superSalary: this.listRunPayroll[index].super,
        employerContribution: this.listRunPayroll[index].employeeData.employerContribution,
        superTotal: this.listRunPayroll[index].super
      })
    },
    // getDetailEmployees(event) {
    //   this.listBonous = this.currentEmployees.listBonous;
    //   if (this.listBonous.length > 0) {
    //     for (var i = 0; i < this.listBonous.length; i++) {
    //       if (this.listDropdown[i].name == "Bonus/Commission") {
    //         this.listDropdown[i].hide = true;
    //         break;
    //       }
    //     }
    //   }

    //   const empData = {
    //     employeeId: event.employeeId,
    //     companyId: event.companyId,
    //   };

    //   ApiManager.employeeTermination(empData)
    //     .then((response) => {
    //       this.terminationDate = response[0];
    //     }).catch((error) => {
    //       this.$logger.error(`getDetailEmployees: ${error}`)
    //     });



    //   if (!this.isVisibeChangeData) {
    //     const data = {
    //       startPayPeriod: moment(event.startPayPeriod).format("YYYY-MM-DD"),
    //       endPayPeriod: moment(event.endPayPeriod).format("YYYY-MM-DD"),
    //       employeeId: event.employeeId,
    //       companyId: event.companyId,
    //     }

    //     ApiManager.empLeaveDetail(data)
    //       .then((response) => {
    //         this.changeData = true;
    //         this.listExtraHours = response.empPayDetail.listExtraHours;
    //         if (this.listExtraHours.length) {
    //           this.changeData = false;
    //         }
    //         this.listExtraHours.map((extraHours) => {
    //           extraHours.name = extraHours.payslipName;
    //           extraHours.rate = extraHours.normalRate;
    //           extraHours.isExtraHourVisible = false;
    //         })
    //         this.listDeduction = response.empPayDetail.listDeduction;
    //         this.listDeduction.map((deduction) => {
    //           deduction.isDeductionVisible = false;
    //         })
    //         this.listAllowance = response.empPayDetail.listAllowance;
    //         this.listAllowance.map((allowance) => {
    //           allowance.isAllowanceVisible = false;
    //         })
    //         this.listLeaveDetail = response.empLeave;
    //         this.listLeaveDetail.map((leaveData) => {
    //           leaveData.startDate = moment(leaveData.startDate).format('DD/MM/YYYY')
    //           leaveData.endDate = moment(leaveData.endDate).format('DD/MM/YYYY')
    //         })
    //         this.isVisibeChangeData = true;
    //       }).catch((error) => {
    //         this.$logger.error(`getDetailEmployees - get emp leave: ${error}`)
    //       });
    //   }
    //   else {
    //     this.listExtraHours = this.currentEmployees.listExtraHours || [];
    //     this.listExtraHours.map((extraHours) => {
    //       extraHours.name = extraHours.payslipName;
    //       extraHours.rate = extraHours.normalRate;
    //       extraHours.isExtraHourVisible = false;
    //     })
    //     this.listDeduction = this.currentEmployees.listDeduction || [];
    //     this.listDeduction.map((deduction) => {
    //       deduction.isDeductionVisible = false;
    //     })
    //     this.listAllowance = this.currentEmployees.listAllowance || [];
    //     this.listAllowance.map((allowance) => {
    //       allowance.isAllowanceVisible = false;
    //     })
    //     this.listLeaveDetail = this.currentEmployees.listLeaveDetail || [];
    //   }

    //   this.hadJobkeeper = false;

    //   this.listAllowance.forEach((element) => {
    //     if (element.noteJobKeeper != null && element.noteJobKeeper != "") {
    //       this.hadJobkeeper = true;
    //       element.type = "JOBKEEPER";
    //       this.start = "";
    //       this.finish = "";
    //       this.noteStarted = element.noteStarted;
    //       this.noteFinished = element.noteFinished;
    //       if (this.noteStarted != "") {
    //         var codeStart = element.noteStarted.slice(-4);
    //         for (var i = 0; i < this.listFN.length; i++) {
    //           if (this.listFN[i].code == codeStart) {
    //             this.start = this.listFN[i];
    //           }
    //         }
    //       }
    //       if (this.noteFinished != "") {
    //         var codeFinish = element.noteFinished.slice(-4);
    //         for (i = 0; i < this.listFN.length; i++) {
    //           if (this.listFN[i].code == codeFinish) {
    //             this.finish = this.listFN[i];
    //           }
    //         }
    //       }

    //       for (i = 0; i < this.listDropdown.length; i++) {
    //         if (this.listDropdown[i].name == "JobKeeper Payment") {
    //           this.listDropdown[i].hide = true;
    //           break;
    //         }
    //       }
    //     }
    //   });

    //   this.listTermination = this.currentEmployees.listTermination;
    //   if (this.listTermination.length > 0) {
    //     for (i = 0; i < this.listDropdown.length; i++) {
    //       if (this.listDropdown[i].name == "Termination") {
    //         this.listDropdown[i].hide = true;
    //         break;
    //       }
    //     }
    //   }
    // },
    seeETPEarning(data) {
      this.$modal.show('modal-ETP-earning', {
        etpTypeR: data.etpTypeR,
        lumSumD: data.lumSumD,
        etpCodeR: data.etpCodeR,
        etpTypeO: data.etpTypeO,
        terminationPay: data.terminationPay,
        earnings: data.earnings
      });
    },

    seeETPTax(data) {
      this.$modal.show('modal-ETP-tax', {
        taxETPTypeR: data.taxETPTypeR,
        taxETPTypeO: data.taxETPTypeO,
        taxTerminationPay: data.taxTerminationPay,
        tax: data.temporaryTax
      });
    },

    clickSave(currentEmployees, index) {
      this.currentIndex = index
      this.currentEmployees = currentEmployees

      this.listBonous = this.currentEmployees.listBonous
      this.listExtraHours = this.currentEmployees.listExtraHours
      this.listAllowance = this.currentEmployees.listAllowance
      this.listDeduction = this.currentEmployees.listDeduction
      this.listTermination = this.currentEmployees.listTermination

      this.currentEmployees.hoursTotal = 0;
      this.currentEmployees.totalExtraHours = 0;
      this.currentEmployees.totalAllowance = 0;
      this.currentEmployees.totalDeduction = 0;

      this.currentEmployees.unitTotal = 0;
      this.currentEmployees.costTotal = 0

      for (var j = 0; j < this.currentEmployees.listExtraHours.length; j++) {
        this.currentEmployees.totalExtraHours = parseFloat(this.currentEmployees.totalExtraHours + (this.currentEmployees.listExtraHours[j].normalRate * this.currentEmployees.listExtraHours[j].hour));
        this.currentEmployees.hoursTotal = parseFloat(this.currentEmployees.hoursTotal) + parseFloat(this.currentEmployees.listExtraHours[j].hour);
        this.currentEmployees.rateTotal = parseFloat(this.currentEmployees.rateTotal) + (this.currentEmployees.listExtraHours[j].normalRate);
      }

      for (var k = 0; k < this.currentEmployees.listAllowance.length; k++) {
        this.currentEmployees.unitTotal = parseFloat(this.currentEmployees.unitTotal + (this.currentEmployees.listAllowance[k].unit));
        this.currentEmployees.costTotal = parseFloat(this.currentEmployees.costTotal + (this.currentEmployees.listAllowance[k].cost));
        this.currentEmployees.totalAllowance = parseFloat(this.currentEmployees.totalAllowance + (this.currentEmployees.listAllowance[k].cost * this.currentEmployees.listAllowance[k].unit));

      }
      //  for(var l=0; l< this.currentEmployees.listAllowance.length;l++) {
      //      this.currentEmployees.totalDeduction = parseFloat( this.currentEmployees.totalDeduction+this.currentEmployees.listDeduction[l].amountDeduction);

      // }
      if (Array.isArray(this.currentEmployees.listDeduction)) {
        for (var l = 0; l < this.currentEmployees.listDeduction.length; l++) {
          this.currentEmployees.totalDeduction = parseFloat(
            this.currentEmployees.totalDeduction +
            (this.currentEmployees.listDeduction[l].amountDeduction || 0) // Tránh lỗi undefined
          );
        }
      } else {
        console.error("listDeduction is not an array", this.currentEmployees.listDeduction);
      }




      this.listRunPayroll[this.currentIndex] = currentEmployees
      this.listRunPayroll[this.currentIndex].earnings = this.getEarnings()
      this.listRunPayroll[this.currentIndex].earningsTax = this.getEarningsTax()
      this.listRunPayroll[this.currentIndex].earningsSuper = this.getEarningsSuper()
      this.listRunPayroll[this.currentIndex].super = this.getSuper()
      this.responseRunPayroll.adjustReview = this.listRunPayroll
    },

    changeEndDate(item, json) {
      var unusedLeave = this.getUnusedLeave(item, json.terminationDate)
      json.unusedLeave = this.truncateNumber(unusedLeave)
    },

    getUnusedLeave(item, endDate) {
      var start = new Date(item.startPayPeriod)
      var end = new Date(endDate)
      var day = 0;
      while (start <= end) {
        if (start.getDay() != 0 && start.getDay() != 6) {
          day += 1
        }
        start.setDate(start.getDate() + 1)
      }
      return (item.ordinaryWorkHours / 5 * day) * 4 / 52 + (item.totalAnnualLeave - item.totalAnnualLeaveUsed)
    },

    truncateNumber(number) {
      return this.utils.truncateNumber(number)
    },

    onChangeWeeklyWorkHours(item, index) {
      if (item.originWorkHours != item.ordinaryWorkHoursRound) {
        item.isChangeWorkHours = true;
      } else {
        item.isChangeWorkHours = false;
      }
      item.ordinaryWorkHours = item.ordinaryWorkHoursRound
      item.ordinaryWorkHoursRound = this.roundNumber(item.ordinaryWorkHours, "RQ")
      if (item.listTermination.length > 0) {
        item.listTermination.forEach(element => {
          this.changeEndDate(item, element)
        })
      }
      this.clickSave(item, index)
    },

    resetWorkHours(item, index) {
      item.isChangeWorkHours = false;
      item.ordinaryWorkHoursRound = item.originWorkHours
      this.onChangeWeeklyWorkHours(item, index)
    },

    onChangePayRatePerHour(item, index) {
      if (item.originPayRate != item.ordinaryPayRateRound) {
        item.isChangePayRate = true;
      } else {
        item.isChangePayRate = false;
      }
      item.ordinaryPayRate = item.ordinaryPayRateRound
      item.ordinaryPayRateRound = this.roundNumber(item.ordinaryPayRate, "RQ")
      this.clickSave(item, index)
    },

    resetPayRate(item, index) {
      item.isChangePayRate = false;
      item.ordinaryPayRateRound = item.originPayRate
      this.onChangePayRatePerHour(item, index)
    },

    check(boolean) {
      if (!boolean) {
        this.numberCheck--
      } else {
        this.numberCheck++
      }
    },

    checkAll() {
      var boolean = !document.getElementById('checkAll').checked
      for (var i = 0; i < this.listRunPayroll.length; i++) {
        if (boolean != this.listRunPayroll[i].isactivePayroll) {
          this.listRunPayroll[i].isactivePayroll = boolean
          this.check(this.listRunPayroll[i].isactivePayroll)
        }
      }
    },

    resetData(id) {
      if (this.listReviewPayroll != undefined && this.listReviewPayroll.length > 0) {
        for (var i = 0; i < this.listReviewPayroll.length; i++) {
          if (this.listReviewPayroll[i].activePayroll == 'ACTIVE' && this.listReviewPayroll[i].employeeData.id == id) {
            for (var j = 0; j < this.listDataBackUp.length; j++) {
              if (this.listReviewPayroll[i].employeeData.id == this.listDataBackUp[j].id) {
                this.listReviewPayroll[i].temporaryTax = this.listDataBackUp[j].tax;
                this.listReviewPayroll[i].overiteTax = this.listDataBackUp[j].overiteTax;
                this.listReviewPayroll[i].annualLeaveAccrued = this.listDataBackUp[j].annualLeaveAccrued;
                this.listReviewPayroll[i].sickLeaveAccrued = this.listDataBackUp[j].sickLeaveAccrued;
                this.listReviewPayroll[i].isChangeInput = false
                break;
              }
            }
          }
        }
      }
    },

    //Review Run Pay roll
    onChangeTax(item) {
      for (var i = 0; i < this.listDataBackUp.length; i++) {
        if (this.listDataBackUp[i].id == item.employeeData.id) {
          if (item.temporaryTax != this.listDataBackUp[i].tax) {
            item.isChangeInput = true
            item.overiteTax = item.temporaryTax
          } else {
            item.isChangeInput = false;
            item.overiteTax = this.listDataBackUp[i].overiteTax
          }
          break;
        }
      }
    },

    roundNumber(number, style) {
      if (style != undefined && style == 'RQ' && number != 0) {
        return Number(this.utils.roundNumber(number).replace(/,/g, ''))
      } else {
        return this.utils.roundNumber(number)
      }
    },

    nextTabsAdjust() {
      this.isVisibeChangeData = true;
      this.runPayrollStage = 'Adjust'
    },

    goToRunOverview() {
      this.$router.push({
        path: "/payrolls",
      });
    }
  },
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import url('/assets/css/runpayroll.css');
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.has-Tax-Template {
  background: #ffeeba !important;
}

.edit-work {
  text-align: center;
}

.edit-rate {
  text-align: center;
}

.item-check {
  text-align: center;
  justify-content: center;
}

.payroll-web {
  display: block;
}

.payroll-mobile {
  display: none;
}

@media (max-width: 575px) {
  .edit-work {
    padding-left: 50px;
  }

  .edit-rate {
    padding-right: 50px;
  }

  .payroll-web {
    display: none;
  }

  .payroll-mobile {
    display: block;
  }
}

// .choose-employee {
//     color: #0052cc;
// }

.choose-employee:hover {
  color: #3c73c7;
}
</style>
